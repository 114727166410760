
import styled from 'styled-components'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'

export const PanelActive = styled(ExpansionPanel)`
  .MuiExpansionPanelSummary-root.Mui-expanded {
    background: #E5E5E5!important;
  }
  &.MuiPaper-root {
    margin: 20px 10px;
  }
`

