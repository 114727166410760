import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Panel from '../components/ExpansionPanel'
import { Box } from '@material-ui/core'

import { Container } from '../styles/pages/faq'

function FaqPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <Container maxWidth="lg" disableGutters={true}>
        <Box component="div">
          <Box
            component="h2"
            style={{ margin: '5vh' }}
            justifyContent="center"
            display="flex"
          >
            Perguntas frequentes - FAQ
          </Box>
        </Box>
        <Panel
          title="A Faculdade Jardins é uma Instituição de Educação Superior (IES)
          Credenciada pelo Ministério da Educação?"
          content="Sim, a Faculdade Jardins é a IES nº 15133, Credenciada através da
          Portaria nº 741, de 09 de Agosto de 2013, publicada no Diário Oficial da União
          (D.O.U.) de 12/08/2013, com plenas condições de regularidade e qualidade para
          você realizar seu Curso Superior de Graduação e/ou de Pós-Graduação."
        />
        <Panel
          title="Onde fica a Sede da Faculdade Jardins"
          content="A Sede principal da Faculdade Jardins localiza-se na Av. Ministro Geraldo
          Barreto Sobral, nº 1496, CEP 49026-010, Bairro Jardins/Aracaju- Sergipe. A
          Faculdade Jardins tem também Polos em vários estados do Brasil e em várias
          cidades de Sergipe e da Bahia (vide relação no site), sempre perto de você para
          realização de seu Curso Superior de Graduação e/ou de Pós- Graduação."
        />
        <Panel
          title="A Mantenedora da Faculdade Jardins é o CENTRO DE EDUCAÇÃO
          SUPERIOR LTDA - CESUL, CNPJ: 11.814.649/0001-62, Inscrição Municipal nº
          082707-3, sediado na Rua Doutor José Roberto Ribeiro, nº 154, Bairro
          Grageru/Jardins – Aracaju/Sergipe. CEP 49.027-090."
        />
        <Panel
          title="Os Cursos de Graduação da Faculdade Jardins são regulares pelo
          Ministério da Educação?"
          content={<p>Sim, todos os nossos cursos de graduação são Autorizados e/ou
            Reconhecidos pelo Ministério da Educação, e estão dentro dos parâmetros
            legais e de qualidade, para que você possa fazer a opção certa na formação
            superior e estar apto para ingressar no mercado de trabalho.
            Veja as opções de graduação presencial e a distância da Faculdade Jardins:

            <ul>
              <li>
                Pedagogia (presencial): Renovação de Reconhecimento Portaria nº 917,
                de 27/12/2018.
              </li>
              <li>
                Letras (presencial): Autorização Portaria nº 427, de 30/08/2013, D.O.U
                de 03/09/2013.
              </li>
              <li>
                Administração (presencial): Autorização Portaria nº 200, de 02/06/2016,
                D.O.U. de 06/06/2016.
              </li>
              <li>
                Ciências Contábeis (presencial): Autorização Portaria nº 674, de
                04/07/2017, D.O.U. de 06/07/2017.
              </li>
              <li>
                CST em Gestão de Recursos Humanos (presencial): Autorização
                Portaria nº 1.243, de 30/11/2017, D.O.U. de 1º/12/2017.
              </li>
              <li>
                Enfermagem (presencial): Autorização Portaria nº 1.364, de 21/12/2017,
                D.O.U. de 22/12/2017.
              </li>
              <li>
                Serviço Social (presencial): Autorização Portaria nº 940, de 28/08/2017,
                D.O.U. de 29/08/2017.
              </li>
              <li>
                Pedagogia (a distância): Autorização Portaria nº 787, de 1º/11/2018,
                D.O.U. de 06/11/2018.
              </li>
              <li>
                Administração (a distância): Autorização Portaria nº 239, de 22/05/2019,
                D.O.U. de 27/09/2019.
              </li>
              <li>
                CST em Gestão de Recursos Humanos (a distância): Autorização
                Portaria nº 898, de 20/12/2018, D.O.U. de 21/12/2018.
              </li>
              <li>
                Matemática Licenciatura (a distância): Autorização Portaria nº 1.178, de
                28/10/2021, D.O.U. de 29/10/2021.
              </li>
              <li>
                CST em Logística (a distância): Autorização Portaria nº 11, de
                07/01/2022, D.O.U. de 10/01/2022.
              </li>
              <li>
                Letras Licenciatura (a distância): Autorização Portaria nº 175, de
                15/06/2020, D.O.U. de 22/06/2020.
              </li>
              <li>
                História Licenciatura (a distância): Autorização Portaria nº 1.155, de
                16/10/2021, D.O.U. de 19/10/2021.
              </li>
              <li>
                Geografia Licenciatura (a distância): Autorização Portaria nº 1.260, de
                18/11/2021, D.O.U. de 19/11/2021.
              </li>
              <li>
                CST em Gestão Comercial (a distância): Autorização Portaria nº 1.220,
                de 08/11/2021, D.O.U. de 09/10/2021.
              </li>
              <li>
                CST em Gestão Ambiental (a distância): Autorização Portaria nº 1.375,
                de 02/12/2021 D.O.U. de 03/12/2021.
              </li>
              <li>
                CST em Análise e Desenvolvimento de Sistemas (a distância):
                Autorização Portaria nº 1.375, de 02/12/2021 D.O.U. de 03/12/2021.
              </li>
              <li>
                CST em Marketing (a distância): Autorização Portaria nº 591, de
                14/04/2022 D.O.U. de 18/04/2022.
              </li>
              <li>
                Serviço Social Bacharelado (a distância): Autorização Portaria nº 591, de
                14/04/2022 D.O.U. de 18/04/2022
              </li>
            </ul></p>}
        />
        <Panel
          title="A Faculdade Jardins é Credenciada pelo Ministério da Educação para atuar na Pós-Graduação Lato Sensu - modalidade presencial?"
          content="Sim, toda IES Credenciada pelo MEC está autorizada a atuar na PósGraduação Lato Sensu - modalidade presencial, conforme prevê a legislação
          educacional vigente*. Dessa forma, a Faculdade Jardins atua na PósGraduação Lato Sensu - modalidade presencial na sua Sede em Aracaju, e em
          outros municípios do Estado de Sergipe, da Bahia e dos demais estados do
          Brasil, sempre perto de você para realização de sua Pós-Graduação."
        />
        <Panel
          title="A Faculdade Jardins é Credenciada pelo Ministério da Educação para atuar na modalidade à distância (EaD)?"
          content="Sim, a Faculdade Jardins é Credenciada pelo MEC para atuar na PósGraduação Lato Sensu - modalidade EaD, por meio da Portaria nº 173, de 03 de Fevereiro de 2017, publicada no D.O.U. de 06/02/2017, e para atuar na Graduação - modalidade EaD, por meio da Portaria nº 918, de 15 de Agosto de2017, publicada no D.O.U de 16/08/2017, sempre perto de você para realização de seu Curso Superior de Graduação e/ou de Pós-Graduação."
        />
        <Panel
          title="Além da Sede, a Faculdade Jardins dispõe de outras Unidades, Polos de Apoio Presencial e/ou escritórios avançados?"
          content={<p>Sim, a Faculdade Jardins dispõe de uma estrutura de suporte para suplantar a sua atuação nas modalidades presencial e a distância, e estar sempre perto de você para realização de seu Curso Superior de Graduação e/ou de Pós-Graduação, conforme segue abaixo:

            <p>
              <u><b>Sede da Faculdade Jardins</b></u>: situada em Aracaju/SE, onde funcionam os
              Cursos de Graduação presencial e Pós-Graduação Lato Sensu presencial da Sede, onde funciona também o Núcleo de Educação a Distância (NeaD).
            </p>
            <p>
              <u><b>Unidade Administrativa da Mantenedora em São Paulo</b></u>: situada no estado
              de São Paulo, Capital, se constitui como apoio administrativo, financeiro e acadêmico da Faculdade Jardins para suplantar a operacionalização do EaD – graduação e pós-graduação - para todo o Brasil, inclusive como Polo de apoio presencial para suporte a realização de atividades presenciais dos Cursos de Graduação EaD.
            </p>
            <p>
              <u><b>Polos de Apoio Presencial</b></u>: unidades de apoio para a Graduação EaD e PósGraduação presencial em todo o Brasil.
            </p>
            <p>
              <u><b>Endereços fora da Sede para funcionamento de Pós-Graduação Lato Sensu Presencial</b></u>: estruturas educacionais específicas, disponíveis mediante aluguel, convênio, e/ou parceria, devidamente cadastradas no sistema e-mec em vinculação aos Cursos de Pós-Graduação Lato Sensu presenciais a serem ofertados no respectivo endereço.
            </p>
          </p>}
        />
        <Panel
          title="Os Cursos da Faculdade Jardins de Pós-Graduação Lato Sensu, modalidade presencial e a distância, têm validade nacional conforme prevê a legislação vigente?"
          content="Sim, os nossos Cursos de Pós-Graduação Lato Sensu, tanto presenciais quanto a distância, têm validade nacional porque são ofertados por uma IES Credenciada pelo MEC e cumprem a legislação educacional vigente*, por isso os Cursos da Faculdade Jardins são plenamente regulares para você realizar seu Curso Superior de Graduação e/ou de Pós-Graduação."
        />
        <Panel
          title="Os Cursos da Faculdade Jardins de Pós-Graduação Lato Sensu, modalidade presencial e a distância, se encontram devidamente inseridos no Cadastro Nacional de Oferta de Cursos de Pós-Graduação Lato Sensu do Ministério da Educação?"
          content={<p>Sim, os nossos Cursos de Pós Graduação Lato Sensu, presencial e
            EaD, atendem plenamente o quanto previsto na Resolução CNE/CES nº
            02/2014, de maneira a estarem plenamente inseridos no Cadastro Nacional de
            Oferta de Cursos de Pós-Graduação Lato Sensu do Ministério da Educação,
            para que você possa realizar sua Pós-Graduação com plena segurança,
            conforme se pode consultar no endereço eletrônico <a href="http://emec.mec.gov.br/" target="_blank">http://emec.mec.gov.br/</a> ou
            diretamente na “aba” ESPECIALIZAÇÃO do link abaixo:
            <p><a href="http://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTUxMzM=" target="_blank">
              http://emec.mec.gov.br/emec/consultacadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTUxMzM=</a></p></p>}
        />
        <Panel
          title="Como funcionam os Cursos de Pós-Graduação Lato Sensu a distância da Faculdade Jardins?"
          content="Contemplando o que prevê a legislação educacional vigente*, principalmente a Resolução CNE/CES nº 01/2018, que estabelece normas para o funcionamento da Pós-Graduação, bem como, atendendo os princípios de qualidade propostos pelo Ministério da Educação e pela própria Faculdade Jardins, de maneira a ofertar Cursos que atendem plenamente os seus anseios de formação pós-graduada. Os Cursos são desenvolvidos virtualmente através de atividades realizadas em nosso Ambiente Virtual de Aprendizagem (AVA) - Plataforma e-jardins, que viabiliza a interação aluno x docentes e aluno x conhecimento; o e-jardins contém vários conteúdos e ferramentas voltadas ao aprendizado e dispõe de todos os recursos necessários para viabilizar a qualidade do processo de ensino aprendizagem a distância voltado para sua formação profissional, tais como chat, fórum, exercícios de fixação, avaliações de aprendizagem randômicas, links, vídeos, textos, powerpoints, hipertextos, videoaulas, ebooks, portal de periódicos, biblioteca virtual, etc."
        />
        <Panel
          title="A legislação educacional vigente* exige atividades presenciais para os Cursos de Pós-Graduação Lato Sensu a distância?"
          content="Não, a legislação educacional vigente* não estabelece obrigatoriedade para atividades presenciais nos Cursos de Pós-Graduação Lato Sensu."
        />
        <Panel
          title="A legislação educacional vigente* permite que um mesmo aluno realize dois cursos de Pós-Graduação Lato Sensu ao mesmo tempo?"
          content="Sim, pois não há restrições na legislação educacional vigente* para a realização de dois ou mais Cursos de Pós-Graduação Lato Sensu ao mesmo tempo. Entretanto, tal situação não deve trazer prejuízo a qualidade na realização dos Cursos. Desta forma, você pode se qualificar aproveitando a disponibilidade de tempo para otimizar sua formação profissional voltada para o mercado de trabalho e conte com a Pós-Graduação da Faculdade Jardins para isso!"
        />
        <Panel
          title="Qual o prazo de integralização de um curso de Pós-Graduação Lato Sensu?"
          content="A legislação educacional vigente* não prevê tempo de integralização mínimo para os cursos de Pós-Graduação Lato Sensu, se restringindo apenas a prever a Carga Horária mínima de 360 h (trezentos e sessenta horas), conforme a Resolução CNE/CES nº 01/2018. Tanto no presencial, quanto no EaD, a integralização dos cursos lato sensu se condiciona ao cumprimento das atividades presenciais e/ou virtuais previstas, observando o Cronograma do respectivo Curso. Desta forma, para você agilizar sua formação, na Faculdade Jardins o tempo mínimo para conclusão de um Curso de Especialização lato sensu presencial e/ou a distância é o previsto no Cronograma do respectivo Curso, sempre com rematrícula trimestral. "
        />
        <Panel
          title="Qual o prazo para entrega do Certificado para o aluno concluinte de Curso de Pós-Graduação Lato Sensu da Faculdade Jardins?"
          content="Após a conclusão do Curso, o aluno fará o requerimento de seu Certificado que será emitido e, posteriormente, entregue/encaminhado num prazo máximo de 45 (quarenta e cinco) dias úteis para os Cursos presenciais e para os Cursos EaD. Nos casos de urgência devidamente justificada, na medida do possível, esse prazo poderá ser diminuído atendendo o quanto solicitado. Não perca tempo para conquistar o seu Certificado de PósGraduação Lato Sensu na Faculdade Jardins!!!!"
        />
        <Panel
          title="Qual a diferença de um Diploma ou Certificado emitido por um Curso presencial de outro emitido por um Curso EaD?"
          content=" Não há diferenças nos Diplomas e Certificados emitidos pelos cursos na modalidade presencial ou na modalidade a distância; inclusive, conforme a legislação educacional vigente*, os documentos acadêmicos que comprovam a conclusão do Curso não podem conter qualquer registro da modalidade em que o Curso foi realizado. Portanto, os Diplomas e Certificados de cursos presenciais e EaD têm a mesma validade nacional! Por isso, mesmo, inicia logo sua formação superior, optando por Cursos presenciais e/ou a distância, e conte com a Faculdade Jardins para isso!!!"
        />
        <Panel
          title="Como se estrutura a educação Superior no Brasil?"
          content={
            <p>
              <p>
                Conforme prevê a Lei de Diretrizes e Bases da Educação Nacional
                (LDB) - Lei nº 9.394/96, no seu Art. 44, os seguintes Cursos e programas
                fazem parte da Educação Superior:
              </p>
              <p>
                “A educação superior abrangerá os seguintes cursos e programas:
                (...)
                <p>II - de <b><u>graduação</u></b>, abertos a candidatos que tenham concluído o
                  ensino médio ou equivalente e tenham sido classificados em processo seletivo;</p>
                <p>III - de <b><u>pós-graduação</u></b>, compreendendo programas de mestrado
                  e doutorado, cursos de especialização, aperfeiçoamento e outros, abertos a
                  candidatos diplomados em cursos de graduação e que atendam às exigências
                  das instituições de ensino;</p>
                <p>IV – de <b><u>extensão</u></b>, abertos a candidatos que atendam aos
                  requisitos estabelecidos em cada caso pelas instituições de ensino.” (GRIFO
                  NOSSO)</p>
              </p>
              <p>A Graduação é ofertada, conforme a LDB, através de Cursos de Licenciatura e Bacharelado. </p>
              <p>Já o Decreto nº 5.154/2004, insere também os Cursos Superiores de
                Tecnologia como graduação tecnológica:
                “Art. 1º A educação profissional, prevista no art. 39 da Lei no 9.394, de 20 de
                dezembro de 1996 (Lei de Diretrizes e Bases da Educação Nacional),
                observadas as diretrizes curriculares nacionais definidas pelo Conselho
                Nacional de Educação, será desenvolvida por meio de cursos e programas de: </p>
              <p>I - formação inicial e continuada de trabalhadores;</p>
              <p>II - educação profissional técnica de nível médio; e</p>
              <p>III - educação profissional tecnológica de graduação e de pósgraduação.” (GRIFO NOSSO)</p>
              <p>Portanto, existe um leque de opções para você iniciar de imediato sua
                formação superior!!! E conte com a Faculdade Jardins para isso, pois dispomos de todos os tipos e modalidades de Cursos superiores... consulte nosso site!!!</p>
            </p>
          }
        />
        <Panel
          title="E a Pós-Graduação no Brasil? Como se estrutura?"
          content="A Pós-Graduação foi aprovada em 03/12/1965, através do Parecer nº 977/65, C.E.Su. Conforme a Legislação educacional vigente*, e, em específico, a Resolução CNE/CES nº 01/2018, a estrutura atual de pós-graduação prevê os Cursos de Pós-Graduação Lato Sensu: Especialização; e os cursos de PósGraduação Stricto Sensu: Mestrado e Doutorado, que podem ser ministrados tanto na modalidade presencial, quanto a distância. Na Faculdade Jardins, disponibilizamos de mais de 200 (duzentos) Cursos de Pós-Graduação Lato Sensu presenciais e a distância para otimizar sua formação profissional... escolha o seul!!!"
        />
        <Panel
          title="Qual a diferença entre um Curso de Especialização profissional de um Curso de Especialização Lato Sensu?"
          content="Os Conselhos e demais segmentos profissionais podem organizar cursos que capacitam, especializam e/ou qualificam profissionais em determinadas áreas de seu domínio, com a emissão de Certificados dos estudos realizados que têm fins específicos no contexto profissional respectivo, mas não são regulados pelo Ministério da Educação (MEC), portanto, são Cursos livres, ou seja, não são Cursos de Especialização Lato Sensu! Os Cursos de Pós-Graduação Lato Sensu - Especialização são regulados pelo MEC, e por isso, obrigatoriamente, devem ser ministrados por uma Instituição de Educação Superior (IES) e atender plenamente a legislação educacional vigente*, em específico a Resolução CNE/CES nº 01/2018."
        />
        <Panel
          title="É permitido Convênios, Contratos e/ou Parcerias para oferta de PósGraduação Lato Sensu?"
          content="Sim, desde que a IES não transfira para terceiros suas prerrogativas de instituição de ensino superior."
        />
        <Panel
          title="Os Curso de Graduação e de Pós-Graduação Lato Sensu devem ser registrados no Sistema CONFEA/CREA?"
          content={
            <p>
              <p>
                Conforme competência legal prevista na legislação educacional
                vigente*, a educação superior e seus Cursos são regulados exclusivamente
                pelo Ministério da Educação (MEC).
                Quanto aos Cursos de Graduação e de Pós-Graduação Lato Sensu da área de
                Engenharia e Agronomia, a competência de regulação também é do MEC,
                entretanto, cabe um cadastro suplementar no Sistema CONFEA/CREA, para
                definição das atribuições profissionais vinculados ao mesmo conforme a
                formação realizada.
              </p>
              <p>
                No caso das Pós-Graduações Lato Sensu, esse cadastro é realizado apenas
                para as <b><u>PÓS-GRADUAÇÕES LATO SENSU QUE PODEM PROMOVER A
                  EXTENSÃO DAS ATRIBUIÇÕES PROFISSIONAIS VINCULADAS AO
                  SISTEMA CONFEA/CREA.</u></b>
              </p>
              <p>
                Dessa forma, cabe as IES solicitarem o cadastramento do respectivo curso no
                Sistema CONFEA/CREA, para que sejam apreciados os currículos realizados e
                definidas as atribuições que serão concedidas aos alunos egressos dos
                respectivos Cursos.
              </p>
            </p>
          }
        />
        <Panel
          title="A Faculdade Jardins e seus Cursos de Pós-Graduação Lato Sensu estão cadastradas no Sistema CONFEA/CREA?"
          content="Sim. A Faculdade Jardins e o Curso de Pós-Graduação Lato Sensu em Energia Elétrica - Sistemas de Potência, foram cadastrados no Sistema CONFEA/CREA, após tramitação do Protocolo 1695483/2018, que foi deferido na Reunião 442/2019, através da Decisão PL nº 280/2019 DA 442R.O. Sugerimos aos interessados conhecerem o teor da aprovação, de responsabilidade do Sistema CONFEA/CREA, antes de se matricularem."
        />
        <Panel
          title="A Faculdade Jardins já protocolou a solicitação de cadastramento dos demais Cursos de Pós-Graduação Lato sensu no Sistema CONFEA/CREA?"
          content="Sim"
        />
        <Panel
          title="Sou formado em Curso Superior e tenho interesse em cursar outra graduação, o que devo fazer?"
          content={
            <p>
              Faça sua matrícula e dê entrada da documentação através do e-mail:
              <a href="mailto: matricula@facjardins.com.br"> matricula@facjardins.com.br</a>, a fim de apresentação do diploma da graduação
              e histórico escolar para aproveitamento de disciplinas.
            </p>
          }
        />
        <Panel
          title="Como é feito o aproveitamento de disciplinas?"
          content="É realizado pelo coordenador do Curso que avaliará o histórico/ementas das disciplinas que foram cursadas na IES de origem."
        />
        <Panel
          title="Qual a diferença entre trancamento de matrícula e trancamento de disciplina?"
          content={
            <p>
              <ul>
                <li>
                  <b><u>O trancamento de matrícula</u></b> - quando o estudante não cursará nenhuma disciplina, ou seja, não está assistindo aulas naquele período letivo. Deve estar atento ao período de trancamento.
                </li>
                <li>
                  <u><b>O trancamento de disciplina</b></u> é quando o estudante pretende deixar de cursar uma ou mais disciplinas entre as disciplinas matriculadas.
                </li>
              </ul>
            </p>
          }
        />
        <Panel
          title="Tranquei meu curso e agora quero retornar, como faço?"
          content="Solicita na Secretaria da Faculdade ou na Coordenação do seu Polo o reingresso, que procederá com a conclusão da sua matrícula."
        />
        <Panel
          title="Quero cancelar o curso, como faço?"
          content="Solicita na secretaria da Faculdade ou na Coordenação do seu Polo o cancelamento a fim de análise e parecer."
        />
        <Panel
          title="Como faço para solicitar Matrícula fora do prazo?"
          content="Todas as solicitações podem ser requeridas junto à Secretaria da Faculdade ou na Coordenação do seu Polo que avaliará o processo."
        />
        <Panel
          title="Como consulto minhas faltas, notas e disciplinas?"
          content="Através do Sistema Acadêmico"
        />
        <Panel
          title="A Secretaria da Faculdade Jardins funciona em qual horário?"
          content="Segunda-feira a sexta-feira: das 8h às 22h, e aos sábados de 8h às 12h."
        />
        <Panel
          title="Meu Professor inseriu uma nota errada no sistema. O que devo fazer?"
          content="Solicita a Secretaria da Faculdade ou ao seu Plo que abra um processo de retificação de nota."
        />
        <Panel
          title="Se eu for reprovado em uma disciplina e novamente realizar matrícula para cursá-la no período letivo seguinte, poderei desistir dessa disciplina?"
          content="Sim, a desistência de matrícula em disciplina é permitida uma vez, caso o estudante ainda não tenha usufruído deste direito."
        />
        <Panel
          title="Como posso tirar minhas dúvidas sobre as disciplinas do curso?"
          content="Com a Coordenação do seu Curso."
        />
        <Panel
          title="Estou no último ano da Graduação, posso iniciar a Pós-Graduação?"
          content="Não, de acordo a Resolução nº 1/2018 do Conselho Nacional de Educação. Apenas portadores de diploma de curso superior podem fazer matrícula em cursos de Pós-Graduação Lato Sensu."
        />
        <Panel
          title="Sou formado em Curso Superior de Tecnologia, posso Cursar a PósGraduação?"
          content="Sim, você pode. Qualquer pessoa portadora de um diploma de Graduação (bacharelado, licenciatura ou tecnológico) pode cursar uma PósGraduação Lato Sensu (especializações)."
        />
        <Panel
          title="É obrigatório o TCC para Graduação?"
          content={
            <p>
              <p>
                Não, de acordo com o Parecer CNE/CES 146/2002 foi estabelecido que:
              </p>
              <p>
                “No conjunto das Diretrizes Curriculares Nacionais e das Diretrizes Curriculares
                Gerais dos Cursos de Graduação, a Monografia/Trabalho de Conclusão de
                Curso vêm sendo concebidos ora como um conteúdo curricular opcional, ora
                como obrigatório. Nos cursos objeto do presente Parecer, a Monografia se
                insere no eixo dos conteúdos curriculares opcionais, cuja adequação aos
                currículos e aos cursos ficará a cargo de cada Instituição que assim optar, por
                seus colegiados superiores acadêmicos.”
              </p>
              <p>
                Diante disto, fica claro que a exigência da realização do TCC é optativa e
                depende das decisões tomadas por cada Instituição.
              </p>
            </p>
          }
        />
        <Panel
          title="Quais os documentos necessários para realizar minha matrícula para Graduação?"
          content={
            <p>
              <ul>
                <li>Certidão de Nascimento ou Casamento</li>
                <li>CPF</li>
                <li>RG</li>
                <li>Comprovante de Residência</li>
                <li>Histórico do Ensino Médio</li>
                <li>Título de Eleitor</li>
                <li>Carteira de Reservista (Masculino)</li>
              </ul>
            </p>
          }
        />
        <Panel
          title="Quais os documentos necessários para realizar minha matrícula para PósGraduação?"
          content={
            <p>
              <ul>
                <li>Certidão de Nascimento ou Casamento</li>
                <li>CPF</li>
                <li>RG</li>
                <li>Comprovante de Residência</li>
                <li>Histórico de Graduação</li>
                <li>Diploma de Graduação</li>
                <li>Título de Eleitor</li>
                <li>Carteira de Reservista (Masculino)</li>
              </ul>
            </p>
          }
        />
        <Panel
          title="Como faço caso não alcance a média da disciplina?"
          content="Deve fazer a Avaliação Complementar, para isso mantenha contato com a Secretaria e sua Coordenação de Polo."
        />
        <Panel
          title=" Como faço para refazer uma disciplina que fiquei reprovado após ter feito ou não a Avaliação Complementar?"
          content={
            <p>
              <ul>
                <li>Aluno Sede - pode acessá-lo através do Sistema Acadêmico-no módulo
                  Financeiro ou através do Setor Financeiro.
                  <a href="https://app62.activesoft.com.br/sistema/sistema.1003830/TelasIES/Login.asp?Login=&Referencia=" target="_blank">https://app62.activesoft.com.br/sistema/sistema.1003830/TelasIES/Login.asp?Login=&Referencia=</a></li>
                <li>Aluno Polo - deve acessar através do sistema Saber em Rede: <a href="https://afiliado.saberemrede.net/login." target="_blank">https://afiliado.saberemrede.net/login.</a></li>
              </ul>
            </p>
          }
        />
        <Panel
          title="Como será o funcionamento do Polo?"
          content="O polo dará suporte ao aluno nas diversas atividades: apoio pedagógico, realização de avaliação presencial, apoio administrativo, dentre outros."
        />
        <Panel
          title="Fiz a matrícula e quero desistir do curso. Tenho direito à restituição de valores investidos?"
          content="Verifique as cláusulas do seu Contrato de Prestação de Serviços Educacionais."
        />
      </Container>
    </Layout>
  )
}

export default FaqPage
