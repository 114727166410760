import React from 'react'
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PanelActive } from './styles';

export default function Panel(props) {
  return (
    <>
      <PanelActive>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          {props.title}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{props.content}</ExpansionPanelDetails>
      </PanelActive>
    </>
  )
}
